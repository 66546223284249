import React, { Component } from 'react';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import Layout from 'components/layout';
import Bottom from 'components/Bottom/Bottom';
import SearchResults from 'components/knowledge-base/SearchResults';
import CardsGrid from 'components/knowledge-base/CardsGrid';
import Translate from 'components/knowledge-base/Translate/Translate';
import * as s from './KnowledgeBaseRoot.module.css';

class KnowledgeBaseRoot extends Component {
  render() {
    const data = _omit(_get(this.props, 'pageContext.data', {}), []);
    const langs = _get(this.props, 'pageContext.langs', []);
    const currentLang = _get(this.props, 'pageContext.currentLang', 'en');

    return (
      <Layout page="knowledge-base" lang={currentLang} registrationURL={_get(this.props, 'pageContext.registrationURL', '')} langs={langs}>
        <div className={s.root}>
          <div className={s.header}>
            <h1 className={s.title}>
              <Translate id="Knowledge Base" lang={currentLang} />
            </h1>
          </div>
          <SearchResults>
            <CardsGrid data={data} lang={currentLang} />
          </SearchResults>
        </div>
        <Bottom/>
      </Layout>
    )
  }
}

export default KnowledgeBaseRoot;
