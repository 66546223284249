import React, { Component } from 'react';
import { FormattedMessage as L } from 'react-intl';
import { navigate } from "gatsby";
import Link from 'components/Link';
import { preparePathWithSlash } from 'helpers/index';
import * as s from './card.module.css';

class Card extends Component {
  handleClick = () => {
    navigate(preparePathWithSlash(this.props.path));
  }

  render() {
    const {
      title,
      ico,
      icoWidth,
      articles,
    } = this.props;

    return (
      <div className={s.root}>
        <div className={s.ico}><img src={ico} width={parseInt(icoWidth)} alt="ico" /></div>
        <div>
          <h2 className={s.title}>{title}</h2>
          <div className={s.descText}>
            {articles.map((item, index) => (
              <Link key={`${index}_link-kb`} to={item.path} className={s.link}>{item.name}</Link>
            ))}
          </div>
          <button
            className={s.btn}
            onClick={this.handleClick}
          >
            <L id="kb.card.button" defaultMessage="Show All"/>
          </button>
        </div>
      </div>
    );
  }
}

export default Card;
