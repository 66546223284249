import React, { Component } from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import Card from 'components/knowledge-base/Card';
import getKbContentsDescription from 'utils/kb-category-info';
import catDescription from 'components/knowledge-base/CardsGrid/description.json';
import * as s from './card.module.css';

class CardsGrid extends Component {
  render() {
    const { data, lang } = this.props;

    const array = _map(data, (data, title) => ({ title, data }));
    const grid = _sortBy(array, ({ title }) => {
      return _get(catDescription, `${lang}.${title}.order`);
    });

    return (
      <div className={s.root}>
        {
          _map(grid, ({ title, data }) => {
            if (!title || !data) {
              return null;
            }

            const {
              name,
              button,
              articles,
              isOrange,
              ico,
              icoWidth,
            } = getKbContentsDescription(title, lang);

            return (
              <Card
                key={title}
                lang={lang}
                title={name}
                button={button}
                articles={articles}
                isOrange={isOrange}
                ico={ico}
                icoWidth={icoWidth}
                path={_get(data, 'path')}
              />
            );
          })
        }
      </div>
    );
  }
}

export default CardsGrid;
